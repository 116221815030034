<template>
  <div>
    <div
      ref="textbox"
      style="line-height: 23px"
      :style="{ '-webkit-line-clamp': lines }"
      :class="{ 'more-line': isShowOperation && isCollapse }"
      v-html="text"
    ></div>
    <div
      class="operation-box"
      v-if="isShowOperation"
      @click="isCollapse = !isCollapse"
    >
      <template v-if="isCollapse">
        <div style="cursor: pointer">
          <span>展开全部</span>
          <!-- <img class="img" src="@/assets/images/home/close.png" alt="下箭头" /> -->
        </div>
      </template>
      <template v-else>
        <div style="cursor: pointer">
          <span>折叠</span>
          <!-- <img class="img" src="@/assets/images/home/close.png" alt="上箭头" /> -->
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import busEvent from "../busEvent";

export default {
  props: {
    // 要展示的文本
    text: {
      type: String,
      default: "",
    },

    // 要展示的行数, 超过多少行折叠
    lines: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      isShowOperation: false, // 是否显示折叠/展开操作
      isCollapse: false, // 是否折叠 为true时,折叠状态,显示展开按钮   为false时,展开状态,显示折叠按钮
    };
  },
  created() {
    busEvent.$on("resize", () => {
      this.isShowOperation = false;
      this.$nextTick(() => {
        this.doShow();
      });
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.doShow();
    });
  },
  methods: {
    doShow() {
      const dom = this.$refs.textbox;
      const offsetHeight = dom ? dom.offsetHeight : 0;

      const lineHeight = parseInt(dom.style.lineHeight);

      const showHeight = lineHeight * this.lines;

      this.isShowOperation = offsetHeight > showHeight;
      // console.log("====", this.isShowOperation);

      // console.log(dom);
      // console.log(offsetHeight, showHeight);
      if (this.isShowOperation) {
        this.isCollapse = offsetHeight > showHeight;
      }
    },
  },
};
</script>
<style scoped>
.more-line {
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.operation-box {
  display: inline-block;
  color: #007bd9;
  position: relative;
  left: 830px;
  top: 0px;
  margin-top: 10px;
}

.img {
  margin-top: -2px;
  width: 16px;
  height: 16px;
  vertical-align: middle;
}
</style>
