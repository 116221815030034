<template>
  <div class="info-contaner">
    <!-- tab -->
    <div class="tab">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="平台公告" name="first">
          <!-- 案例 -->
          <div v-if="plantMsg.length">
            <el-row class="case">
              <el-row
                class="item"
                type="flex"
                align="middle"
                v-for="(item, index) in plantMsg"
                :key="index"
              >
                <div class="msg-box">
                  <el-row class="contant-box">
                    <el-row class="box">
                      <p class="contant">
                        <img
                          class="horn-img"
                          src="@/assets/images/home/horn.png"
                          alt=""
                        />平台公告
                        <span class="c9 f14 my-date">{{
                          item.createTime
                        }}</span>
                      </p>
                      <p class="date">{{ item.titile }}</p>
                      <CoollapseText
                        :lines="2"
                        :text="item.msgContent"
                      ></CoollapseText>
                      <!-- <p class="date">
                      您的购买会员已支付成功，会员开始日期2021-12-02
                      11:48，会员到期2021-12-02 11:48。
                    </p> -->
                    </el-row>
                  </el-row>
                </div>
                <div class="handle">
                  <!-- <span>2018-10-22 10:22</span> -->
                </div>
              </el-row>
            </el-row>
          </div>
          <el-empty
            style="width: 100%"
            v-else
            description="暂无数据"
          ></el-empty>
        </el-tab-pane>
        <el-tab-pane label="系统消息" name="second">
          <!-- 案例 -->
          <div v-if="sysMsg.length">
            <el-row class="case">
              <el-row
                class="item"
                type="flex"
                align="middle"
                v-for="(item, index) in sysMsg"
                :key="index"
              >
                <div class="msg-box">
                  <el-row class="contant-box">
                    <el-row class="box">
                      <p class="contant">
                        <img
                          class="horn-img"
                          src="@/assets/images/home/horn.png"
                          alt=""
                        />
                        订单消息
                      </p>
                      <p class="date">
                        {{ item.msgContent }}
                      </p>
                    </el-row>
                  </el-row>
                </div>
                <div class="handle">
                  <span>{{ item.createTime }}</span>
                </div>
              </el-row>
            </el-row>
          </div>
          <el-empty
            style="width: 100%"
            v-else
            description="暂无数据"
          ></el-empty>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 分页开始 -->
    <el-pagination
      background
      layout="prev, pager, next"
      :total="listTotal"
      :page-size="pageSize"
      :current-page="page"
      @current-change="pageChange"
      @prev-click="prevChange"
      @next-click="nextChange"
    >
    </el-pagination>
    <!-- 分页结束 -->
  </div>
</template>

<script>
import CoollapseText from "@/components/collapse-text.vue";
import busEvent from "../../busEvent";
export default {
  name: "YoufujichuangInfo",
  components: {
    CoollapseText,
  },
  data() {
    return {
      activeName: "first", //激活项

      plantMsg: [], //系统消息

      sysMsg: [], //系统消息

      // 分页
      // 分页相关数据
      page: 1,
      pageSize: 1,
      listTotal: 0,
    };
  },

  watch: {
    activeName() {
      this.page = 1;
    },
  },

  mounted() {},

  created() {
    //查询平台公告
    this.getPlantMsg();
    //系统消息-分页列表查询
    this.getSysMsg();
  },

  methods: {
    //查询平台公告
    getPlantMsg() {
      let paramData = {
        pageNo: this.page,
        pageSize: this.pageSize,
        msgCategory: 2,
      };
      this.$API
        .getSysMsg(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.listTotal = Number(res.data.total);
            this.plantMsg = res.data.records;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //系统消息-分页列表查询
    getSysMsg() {
      let paramData = {
        pageNo: this.page,
        pageSize: this.pageSize,
        msgCategory: 1,
      };
      this.$API
        .getSysMsg(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.listTotal = Number(res.data.total);
            this.sysMsg = res.data.records;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 当前页码改变
    pageChange(page) {
      if (this.activeName == "first") {
        // 平台公告为激活项
        this.page = page;
        this.getPlantMsg();
      }

      if (this.activeName == "second") {
        // 平台公告为激活项
        this.page = page;
        this.getSysMsg();
      }
    },

    // 点击上一页
    prevChange() {},
    // 点击下一页
    nextChange() {},
    // tab点击
    handleClick(tab, event) {
      busEvent.$emit("resize");
      // console.log(tab, event);
    },
  },
};
</script>

<style lang="scss">
.info-contaner {
  .horn-img {
    width: 18px;
    height: 18px;
    position: relative;
    top: 2px;
    margin-right: 5px;
  }
  box-sizing: border-box;
  padding: 32px;
  .tab {
    margin-top: 13px;
  }
  .el-tabs__active-bar {
    // width: 32px !important;
    // background-color: #ff0015;
  }
  .el-tabs__item {
    font-size: 18px;
    color: #333333;
  }
  .el-tabs__item.is-active {
    font-size: 22px;
    font-weight: bold;
    color: #333333;
  }
  .case {
    background-color: #ffffff;
    width: 100%;
    // height: 105px;
    box-sizing: border-box;
    .msg-box {
      width: 892px;
      float: left;
    }
    .handle {
      height: 19px;
      line-height: 19px;
      float: right;
      font-size: 14px;
      color: #999999;
      .intention {
        margin-left: 16px;
      }
    }
    .item {
      width: 100%;
      // height: 105px;
      box-sizing: border-box;
      display: inline-block;
      padding: 24px;
      cursor: pointer;
      border-bottom: 1px solid #eeeeee;
      transition: box-shadow 0.03s;
      &:hover {
        box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.12);
      }
      &:nth-child(2n + 1) {
        margin-right: 32px;
      }

      &:nth-child(2n) {
        margin-right: 40px;
      }
      .contant-box {
        width: 892px;
        display: inline-block;
      }
      .box {
        width: 892px;
        height: 65px;
        .contant {
          font-size: 14px;
          color: #333;
          .my-date {
            position: relative;
            top: 0px;
            left: 696px;
          }
        }
        .date {
          width: 800px;
          margin-top: 16px;
          font-size: 14px;
          color: #666;
          position: relative;
          top: -5px;
          @include dot(1);
        }
      }
    }
  }
  // 分頁
  .el-pagination {
    text-align: center;
    margin-bottom: 46px;
    margin-top: 32px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #333333;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: red;
    color: #ffffff;
  }
}
</style>